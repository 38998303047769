<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="일정 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && popupParam.calendarScheduleId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <!--제목-->
                <c-text
                  required
                  :editable="editable"
                  label="제목"
                  name="calendarScheduleTitle"
                  v-model="data.calendarScheduleTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :disabled="true"
                  label="작성자"
                  name="userName"
                  v-model="data.userName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="종일"
                  name="dayFlag"
                  v-model="data.dayFlag"
                  @datachange="datachangeDayFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  required
                  type="date"
                  :range="true"
                  :editable="editable"
                  label="일정"
                  name="dts"
                  v-model="data.dts">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4col-lg-4 col-xl-4">
                <c-datepicker
                  v-if="data.dayFlag == 'N'"
                  required
                  :minuteStep="30"
                  type="time"
                  :range="true"
                  :editable="editable"
                  label="시간"
                  name="dts"
                  v-model="data.times">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="editable">
                <c-select
                  :editable="editable"
                  :comboItems="calMsts"
                  type="none"
                  itemText="calendarMstName"
                  itemValue="calendarMstId"
                  name="calendarMstId"
                  label="내 캘린더"
                  v-model="data.calendarMstId"
                  @input="changeMst"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="editable">
                <q-btn :color="data.calendarMstColor" rounded icon="check" label="" v-if="editable">
                  <q-popup-proxy transition-show="flip-up" transition-hide="flip-down" ref="calproxy">
                    <c-card :noHeader="true" title="" class="cardClassDetailForm" :radiusFlag="false" style="width: 300px">
                      <template slot="card-detail">
                        <div class="col-3" style="text-align: center;"
                          v-for="(color, index) in colors"
                          :key="index">
                          <q-btn 
                            :color="color" 
                            rounded 
                            :editable="editable"
                            :icon="data.calendarMstColor == color ? 'check' : ''" 
                            @click="data.calendarMstColor = color"
                            label="" />
                        </div>
                        <div class="col-12">
                          <br/>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  label="내용"
                  name="contents"
                  :rows="10"
                  v-model="data.contents">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="grid"
                  title="참석자"
                  tableId="grid"
                  :columns="grid.columns"
                  :data="data.attendees"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :isExcelDown="false"
                  :editable="editable"
                  :filtering="false"
                  :hideBottom="true"
                  gridHeight="240px"
                  selection="multiple"
                  rowKey="userId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
                      <c-btn label="제외" v-if="editable" icon="remove" @btnClicked="removeRow" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="일정 첨부파일" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="일정 첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'calendar-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        calendarScheduleId: '',
        startDt: '',
        endDt: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MY_CALENDAR',
        taskKey: '',
      },
      colors: ['deep-purple', 'blue', 'green', 'orange', 'blue-grey', 'indigo', 'purple', 'teal', 'light-blue', 'red', 'yellow-9', 'grey-6'],
      saveUrl: transactionConfig.mdm.cal.schedule.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      regUserName: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      mstlistUrl: '',
      calMsts: [],
      data: {
        calendarScheduleId: '',  // 개인달력 일정 아이디
        userId: '',  // 사용자 아이디
        userName: '',  // 사용자 아이디
        calendarMstId: 'CDMC000001',  // 개인달력 구분
        calendarMstColor: 'deep-purple',  // 개인달력 구분 색상
        calendarScheduleTitle: '',  // 일정제목
        dayFlag: 'Y',  // 종일여부
        startDt: '',  // 시작일자
        endDt: '',  // 종료일자
        startTime: '',  // 시작시간
        endTime: '',  // 종료시간
        contents: '',  // 내용
        dts: [],
        times: [],
        attendees: [],
      },
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: false,
          },
        ],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.detailUrl = selectConfig.mdm.cal.schedule.get.url;
      this.mstlistUrl = selectConfig.mdm.cal.mst.list.url;
      this.insertUrl = transactionConfig.mdm.cal.schedule.insert.url;
      this.updateUrl = transactionConfig.mdm.cal.schedule.update.url;
      this.deleteUrl = transactionConfig.mdm.cal.schedule.delete.url;
      this.getMsts();
    },
    datachangeDayFlag() {
      if (this.data.dayFlag == 'N') {
        if (this.data.times.length == 0) {
          const nowtime = new Date();
          this.data.times = [('0' + (nowtime.getHours() + 1)).slice(-2) + ':00', ('0' + (nowtime.getHours() + 1)).slice(-2) + ':30'];
        }
      } else {
        this.data.times = [];
      }
    },
    getMsts() {
      this.$http.url = this.mstlistUrl;
      this.$http.param = {userId: this.$store.getters.user.userId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.calMsts = _result.data;
        this.getDetail();
      },);
    },
    getDetail() {
      if (this.popupParam.calendarScheduleId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {calendarScheduleId: this.popupParam.calendarScheduleId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.data.dts = [this.data.startDt, this.data.endDt];
          if (this.data.dayFlag == 'N') {
            this.data.times = [this.data.startTime, this.data.endTime]
          }
          this.$set(this.attachInfo, 'taskKey', this.popupParam.calendarScheduleId)

          if (this.data.userId != this.$store.getters.user.userId) {
            this.editable = false;
          }
        },);
      } else {
        this.data.dts = [this.popupParam.startDt, this.popupParam.endDt]
        // this.data.startDt = this.popupParam.startDt;
        // this.data.endDt = this.popupParam.endDt;
        this.data.dayFlag = 'Y';
      }
    },
    changeMst(data) {
      let _filter = this.$_.cloneDeep(this.$_.filter(this.calMsts, { calendarMstId: data}));
      if (_filter.length > 0) {
        this.data.calendarMstColor = _filter[0].calendarMstColor;
      }
    },
    saveData() {
      if (this.popupParam.calendarScheduleId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.userId = this.$store.getters.user.userId;
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.startDt = this.data.dts[0];
              this.data.endDt = this.data.dts[1];
              if (this.data.times) {
                this.data.startTime = this.data.times[0];
                this.data.endTime = this.data.times[1];
              } else {
                this.data.startTime = '';
                this.data.endTime = '';
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.calendarScheduleId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.calendarScheduleId = result.data.calendarScheduleId;
      this.$set(this.attachInfo, 'taskKey', this.popupParam.calendarScheduleId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$emit('closePopup');
    },
    addrow() {
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.title = '사용자';
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.data.attendees) this.data.attendees = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.attendees, { userId: item.userId }) === -1 && item.userId != this.$store.getters.user.userId) {
            this.data.attendees.push({
              editFlag: 'C',
              calendarScheduleId: this.popupParam.calendarScheduleId ? this.popupParam.calendarScheduleId : '',
              userId: item.userId,
              userName: item.userName,
              deptName: item.deptName,
              jobName: item.jobName,
            });
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$_.forEach(selectData, item => {
              this.data.attendees = this.$_.reject(this.data.attendees, item);
            })
            this.$refs['grid'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
